import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import "../styles/landing.css"
import logo from "../../static/images/logo-menu.png"
import { FaCircle, FaFacebookF, FaLinkedinIn } from "react-icons/fa"
import twitter from "../../static/images/x-twitter.svg"

const TestAutoevaluacion = () => {
  const data = useStaticQuery(graphql`
    query imageslanding {
        image: allFile(
        filter: { relativeDirectory: { eq: "landing" } }
        sort: { fields: name, order: ASC }
        ) {
        nodes {
            id
            childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp
            }
            }
        }
        },
        logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
        ) {
        nodes {
            id
            childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp
            }
            }
        }
        }
    }
  `)
  
  function Twitter() {window.open ("https://twitter.com/qualoom");}
  function Facebook() {window.open ("https://www.facebook.com/qualoom.expertise.technology")}
  function LinkedIn() {window.open ("https://www.linkedin.com/company/qualoom/mycompany/")}

  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Autoevaluación de Seguridad On-premise | Qualoom</title>
        <meta name="title" content="Autoevaluación de Seguridad On-premise | Qualoom"/>
        <meta name="description" content="Qualoom te ofrece una primera consulta para evaluar el estado del nivel de seguridad de tu empresa con nuestro nuevo test de autoevaluación."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/test-autoevaluacion/"/>
        <link rel="canonical" href="https://www.qualoom.es/test-autoevaluacion/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/test-autoevaluacion/"/>
        <meta property="og:title" content="Autoevaluación de Seguridad On-premise | Qualoom"/>
        <meta property="og:description" content="Qualoom te ofrece una primera consulta para evaluar el estado del nivel de seguridad de tu empresa con nuestro nuevo test de autoevaluación."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/6WIx8XTNxgjK6PO9zjQKOv/af311ad065fc823247d2c2f8e5fe52a6/tw_cards_landing.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/test-autoevaluacion/"/>
        <meta property="twitter:title" content="Autoevaluación de Seguridad On-premise | Qualoom"/>
        <meta property="twitter:description" content="Qualoom te ofrece una primera consulta para evaluar el estado del nivel de seguridad de tu empresa con nuestro nuevo test de autoevaluación."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/6WIx8XTNxgjK6PO9zjQKOv/af311ad065fc823247d2c2f8e5fe52a6/tw_cards_landing.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="cover-image" id="init">
          <div className="header-landing">
            <Link to="/">
              <div className="logo_large">
                <img src={logo} alt="logo Qualoom largo a pantalla completa"/>
              </div>
            </Link>
          </div>
        </div>
      </BackgroundImage>
      <div className="landing-full-content">
        <div className="landing-main">
            <div className="landing-main-content-col-2">
                <h1>Conoce el nivel de seguridad <span>de tu organización</span></h1>
                <p>Realizando nuestro test de autoevaluación podrás tener una idea inicial de su estado y junto con nuestro equipo te ayudaremos a mejorarlo.</p>
                <div className="score-value">
                    <div className="button-score-value">¿Qué significan mis resultados?</div>
                    <div>
                        <p><FaCircle className="red"></FaCircle>0-49 Revisión urgente e implementación de métodos</p>
                        <p><FaCircle className="yellow"></FaCircle>50-79 Debilidades existentes y riesgos muy probables</p>
                        <p><FaCircle className="green"></FaCircle>80-100 Buen trabajo, es conveniente realizar revisiones periódicas para mantener este nivel o alcanzar el 100%</p>
                    </div>
                </div>
                <a href="/test-autoevaluacion-aws/" target="_blank" className="button-test-aws">Realiza nuestro test de autoevaluación en entornos cloud en AWS</a>
            </div>
            <div className="landing-main-content-col-2">
              <div className="test">
                <h3>Test de autoevaluación</h3>
                <p>En este test de autoevaluación nos centraremos en evaluar la seguridad global de tu organización en entornos onpremise.</p>
                <p>Una vez completado el test podrás enviarlo a nuestro equipo para recibir un informe detallado de tus resultados.</p>
                <a href="/documents/test-autoevaluacion.pdf" target="_blank">Empezar cuestionario</a>
              </div>
            </div>
        </div>
      </div>
      <div className="landing-main-image hide">
        <div className="landing-main">
          <div className="landing-main-content-col-2">
            <h2>Qualoom Expertise Technology</h2>
            <p>Una compañía española del sector de las tecnologías de la información con más de 10 años de experiencia que desarrolla su actividad en el ámbito de la Consultoría IT, Soluciones Cloud, BigData & Analytics, Desarrollo de Software, IoT & Robótica Software.</p>
          </div>
        </div>
      </div>
      <div className="landing-main hide">
        <div className="landing-main-content">
          <h2>¿Por qué elegirnos?</h2>
          <div className="why">
            <div className="why-item">
              <h3>Experiencia</h3>
              <p>Más de 10 años de experiencia en cloud y optimización de sistemas, trabajando con los fabricantes líderes y con referencias de éxito como Interflora, Telefónica, Vocento, Liga Profesional de Fútbol, Ministerio de Industria, Viena Capellanes o Tiendanimal.</p>
            </div>
            <div className="why-item">
              <h3>Visión de negocio</h3>
              <p>Entendemos las mejoras tecnológicas desde una perspectiva de negocio, siempre buscando el equilibro entre coste, funcionalidad y nivel de servicio.</p>
            </div>
            <div className="why-item">
              <h3>Visión integrada</h3>
              <p>Abordamos los problemas desde una cultura DevSecOps que tiene en cuenta la infraestructura tecnológica desde la perspectiva del desarrollo, los sistemas y la seguridad. </p>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-footer">
        <div className="footer-copyright">
            <div className="footer-legal">
                <Link to="/aviso-legal/"><h4>Aviso Legal</h4></Link>
                <Link to="/politica-de-privacidad/"><h4>Política de Privacidad</h4></Link>
                <Link to="/politica-de-cookies/"><h4>Política de Cookies</h4></Link>
            </div>
            <span>@{new Date().getFullYear()} Qualoom Expertise Technology. Todos los derechos reservados.</span>
        </div>
        <div className="social-media">
            <span onClick={Twitter} onKeyDown aria-hidden><img className="x-twitter" src={twitter} alt="Twitter"/></span>
            <span onClick={Facebook} onKeyDown aria-hidden><FaFacebookF size={30}></FaFacebookF></span>
            <span onClick={LinkedIn} onKeyDown aria-hidden><FaLinkedinIn size={30}></FaLinkedinIn></span>
        </div>
      </div>
    </div>
  )
}

export default TestAutoevaluacion
